import { theme } from 'game_libs/controllers/themeController';
import { FileSystem } from './files';
import { type FolderApi } from 'tweakpane';
import { features } from 'game_libs/controllers/featureController';

export const DEFAULT_LAYOUT_CONFIG = {
    alpha: 1,
    rotation: { x: 0, y: 0 },
    anchor: { x: 0, y: 0 },
    scale: { x: 1, y: 1 },
    position: { x: 150, y: 3070 },
    width: 100,
    height: 100,
    ignoreAspectRatio: false,
};

export class ThemeEditor {
    protected container: HTMLDivElement;
    fs!: FileSystem;

    constructor(private parent: FolderApi) {}

    async init(): Promise<boolean> {
        try {
            this.fs = new FileSystem();
            const themeData = await this.fs.getFileContent('theme.json');

            console.log(`📝 Theme Editor`, { themeData: theme.themeData });

            theme.update(themeData);

            this.parent = this.parent.addFolder({
                title: 'Theme',
                expanded: true,
            });

            this.parent.addBinding(theme.themeData, 'bgColor').on('change', () => theme.update());
            this.parent
                .addBinding(theme.themeData, 'reelSpacing')
                .on('change', () => theme.update());
            this.parent
                .addBinding(theme.themeData, 'symbolsSpacing')
                .on('change', () => theme.update());

            this.parent
                .addBinding(theme.themeData, 'symbolScaling')
                .on('change', () => theme.update());

            // symbolSize
            this.addDoubleValBinding({
                parent: this.parent,
                data: theme.themeData,
                sectionName: 'symbolSize',
                key1: 'w',
                key2: 'h',
            });

            // bgScaling
            this.addDoubleValBinding({
                parent: this.parent,
                data: theme.themeData,
                sectionName: 'bgScaling',
                key1: 'w',
                key2: 'h',
            });

            // bg
            this.addLayoutEditFolder(theme.themeData, 'bg');

            if (features.logoSeparated) {
                this.addLayoutEditFolder(theme.themeData, 'logo');
            }

            this.addLayoutEditFolder(theme.themeData, 'bgFreeSpins');
            const anticipationSection = this.addLayoutEditFolder(theme.themeData, 'anticipation');

            anticipationSection
                .addBinding(theme.themeData.anticipation, 'horizontalOffset')
                .on('change', () => theme.update());

            this.addLayoutEditFolder(theme.themeData, 'slotMachineLayer');
            this.addLayoutEditFolder(theme.themeData, 'totalWin');
            this.addLayoutEditFolder(theme.themeData, 'bigWin');
            this.addLayoutEditFolder(theme.themeData, 'payLines');

            this.addSlotMachineMaskFolder(theme.themeData, 'slotMachineLayerMask');

            if (theme.themeData.popupButtonOffset !== undefined) {
                this.parent
                    .addBinding(theme.themeData.popupButtonOffset, 'startFS')
                    .on('change', () => theme.update());
                this.parent
                    .addBinding(theme.themeData.popupButtonOffset, 'retriggerFS')
                    .on('change', () => theme.update());
                this.parent
                    .addBinding(theme.themeData.popupButtonOffset, 'stopFSButton')
                    .on('change', () => theme.update());
            }

            return true;
        } catch (error) {
            console.error(`📝`, { error });
        }

        return false;
    }

    async dispose() {
        await this.fs.dispose();
    }

    async save() {
        await this.fs.writeFile('theme.json', theme.themeData);
    }

    private addSpineEditSection(layoutFolder: FolderApi, layoutData: any, sectionName: string) {
        const spineEditSection = layoutFolder.addFolder({
            title: 'Spine',
            expanded: true,
        });

        const button = spineEditSection
            .addButton({ title: layoutData[sectionName].spineData })
            .on('click', async () => {
                const selectedFile: any = await this.fs.selectFile(['.atlas']);

                const fileName = selectedFile[0]?.name.replace('.atlas', '');

                (theme.themeData as any)[sectionName].spineData = fileName;
                button.title = fileName;
            });
    }

    private addLayoutEditFolder(layoutData: any, sectionName: string): FolderApi {
        const layoutFolder = this.parent.addFolder({
            title: sectionName,
            expanded: false,
        });

        layoutData[sectionName] = {
            ...DEFAULT_LAYOUT_CONFIG,
            ...layoutData[sectionName],
        };

        if (layoutData[sectionName].spineData !== undefined) {
            this.addSpineEditSection(layoutFolder, layoutData, sectionName);
        }

        if (layoutData[sectionName].visible !== undefined) {
            layoutFolder
                .addBinding(layoutData[sectionName], 'visible')
                .on('change', () => theme.update());
        }

        layoutFolder
            .addBinding(layoutData[sectionName], 'alpha')
            .on('change', () => theme.update());
        layoutFolder
            .addBinding(layoutData[sectionName], 'width')
            .on('change', () => theme.update());
        layoutFolder
            .addBinding(layoutData[sectionName], 'height')
            .on('change', () => theme.update());

        this.addDoubleValBinding({
            parent: layoutFolder,
            data: layoutData[sectionName],
            sectionName: 'anchor',
            key1: 'x',
            key2: 'y',
        });
        this.addDoubleValBinding({
            parent: layoutFolder,
            data: layoutData[sectionName],
            sectionName: 'position',
            key1: 'x',
            key2: 'y',
        });
        // this.addDoubleValBinding({
        //     parent: layoutFolder,
        //     data: layoutData[sectionName],
        //     sectionName: 'rotation',
        //     key1: 'x',
        //     key2: 'y',
        // });
        this.addDoubleValBinding({
            parent: layoutFolder,
            data: layoutData[sectionName],
            sectionName: 'scale',
            key1: 'x',
            key2: 'y',
        });

        layoutFolder
            .addBinding(layoutData[sectionName], 'ignoreAspectRatio')
            .on('change', () => theme.update());

        return layoutFolder;
    }

    private addSlotMachineMaskFolder(layoutData: any, sectionName: string) {
        const layoutFolder = this.parent.addFolder({
            title: sectionName,
            expanded: false,
        });

        layoutData[sectionName] = {
            ...DEFAULT_LAYOUT_CONFIG,
            ...layoutData[sectionName],
        };

        if (layoutData[sectionName].visible !== undefined) {
            layoutFolder
                .addBinding(layoutData[sectionName], 'visible')
                .on('change', () => theme.update());
        }

        if (layoutData[sectionName].alpha !== undefined) {
            layoutFolder
                .addBinding(layoutData[sectionName], 'alpha')
                .on('change', () => theme.update());
        }

        if (layoutData[sectionName]?.size) {
            this.addDoubleValBinding({
                parent: layoutFolder,
                data: layoutData[sectionName],
                sectionName: 'size',
                key1: 'w',
                key2: 'h',
            });
        }

        this.addDoubleValBinding({
            parent: layoutFolder,
            data: layoutData[sectionName],
            sectionName: 'position',
            key1: 'x',
            key2: 'y',
        });

        if (layoutData[sectionName]?.scale) {
            this.addDoubleValBinding({
                parent: layoutFolder,
                data: layoutData[sectionName],
                sectionName: 'scale',
                key1: 'x',
                key2: 'y',
            });
        }
    }

    private addDoubleValBinding({
        parent,
        data,
        sectionName,
        key1,
        key2,
    }: {
        parent: FolderApi;
        data: any;
        sectionName: string;
        key1: string;
        key2: string;
    }) {
        const bindFolder = parent.addFolder({
            title: sectionName,
            expanded: false,
        });

        bindFolder.addBinding(data[sectionName], key1).on('change', () => theme.update());
        bindFolder.addBinding(data[sectionName], key2).on('change', () => theme.update());
    }

    static get isSupported(): boolean {
        return FileSystem.isSupported;
    }
}
