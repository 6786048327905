import { Layer } from 'game_libs/layers/layerTypes/layer';
import { LayerFactory, LayerType } from 'game_libs/layers/layerTypes/layerFactory';
import { type SpineLayer } from 'game_libs/layers/layerTypes/spineLayer';
import { theme } from 'game_libs/controllers/themeController';
import { features } from 'game_libs/controllers/featureController';

export function BACKGROUND_LAYER(): Layer {
    const defaultBG = LayerFactory.CreateSpine({
        layout: {
            position: {
                x: 0,
                y: 0,
            },
            ...theme.bg,
        },
        events: [
            {
                event: 'enterFreeSpins',
                handler: async (layer: SpineLayer) => {
                    layer.visible = false;
                    layer.stop();
                },
            },
            {
                event: 'enterMainGame',
                handler: async (layer: SpineLayer) => {
                    layer.visible = true;
                    layer.play('animation', true);
                },
            },
        ],
        actions: [
            {
                name: 'show',
                action: async (layer: SpineLayer) => {
                    layer.play('animation', true);
                },
            },
        ],
        spineData: theme.bg.spineData,
    });

/////////////////
    theme.onchange(({ bg }) => {
        defaultBG.setLayoutConfigs([bg]);
        defaultBG.visualUpdate();
    });
//////////////

    const layerChildren = [defaultBG];

    if (features.freeSpins && features.separatedBackground) {
        let fsBG: SpineLayer = null;

        fsBG = LayerFactory.CreateSpine({
            layout: {
                position: {
                    x: 0,
                    y: 0,
                },
                ...theme.bgFreeSpins,
            },
            events: [
                {
                    event: 'enterFreeSpins',
                    handler: async (layer: SpineLayer) => {
                        layer.visible = true;
                        layer.play('animation', true);
                    },
                },
                {
                    event: 'enterMainGame',
                    handler: async (layer: SpineLayer) => {
                        layer.visible = false;
                        layer.stop();
                    },
                },
            ],
            actions: [
                {
                    name: 'show',
                    action: async (layer: SpineLayer) => {
                        layer.play('animation', true);
                    },
                },
            ],
            spineData: theme.bgFreeSpins.spineData,
        });

        layerChildren.push(fsBG);

/////////////////////
        theme.onchange(({ bgFreeSpins }) => {
            fsBG.setLayoutConfigs([bgFreeSpins]);
            fsBG.visualUpdate();
        });
//////////////////
    }

    return LayerFactory.Create(LayerType.CONTAINER, {
        id: 'backgroundLayer',
        layerChildren,
    });
}
