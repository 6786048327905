import { LayerFactory, LayerType } from 'game_libs/layers/layerTypes/layerFactory';
import { theme } from 'game_libs/controllers/themeController';
import { type SpriteLayer } from 'game_libs/layers/layerTypes/spriteLayer';

export const LOGO_LAYER = () => {
    let childLayer: SpriteLayer;

    const layer = LayerFactory.Create(LayerType.CONTAINER, {
        id: 'logo-reels',
        layout: {
            width: '100%',
            height: '100%',
        },
        layerChildren: [
            (childLayer = LayerFactory.CreateSprite({
                texture: theme.logo.texture,
                layout: theme.logo,
            })),
        ],
    });

/////////////////
    theme.onchange(({ logo }) => {
        childLayer.setLayoutConfigs([logo as any]);
        childLayer.visualUpdate();
    });
//////////////

    return layer;
};
