import { ThemeEditor } from '../plugins/themeEditor';
import { DevTools } from '../plugins/DevTools';
import ls from 'localstorage-slim';
import { ButtonApi, FolderApi } from 'tweakpane';
import { getUrlParam } from 'game_libs/utils/gtUrlParams';
import type { Application } from '@pixi/app';

const gameId = getUrlParam('gameId');

declare const EventManager: any;

declare const Connector: any;

export const lsThemeEditorSection = `${gameId}.themeEditor`;
export const lsAutoPlaySection = `${gameId}.autoplay`;

export class Cheats {
    private hostname: string;
    private devTools = new DevTools({
        position: { x: 'left', y: 'top' },
    });
    private themeEditorButton: ButtonApi;

    private themeEditor!: ThemeEditor;
    private themeEditorFolder!: FolderApi;
    private cheatsFolder!: FolderApi;

    constructor(private cheats: any) {}

    async init(app: Application): Promise<void> {
        await this.devTools.init(app);

        const params = new URLSearchParams(window.location.search);

        const serverURL = params.get('server');

        if (!serverURL) {
            throw new Error('No server URL found');
        }

        const endpoint = new URL(serverURL).origin;

        await fetch(`${endpoint}/rigNumbers`, {
            method: 'head',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.buildUI();
    }

    add(title: string, cheat: () => void) {
        this.cheatsFolder.addButton({ title }).on('click', cheat);
    }

    private buildUI() {
        const lsSection = `${gameId}.cheatsOpen`;

        this.cheatsFolder = this.devTools.addFolder({
            title: 'Cheats',
            expanded: ls.get(lsSection) ?? false,
        });

        this.cheatsFolder.element.addEventListener('pointerdown', () => {
            setTimeout(() => ls.set(lsSection, this.cheatsFolder.expanded), 100);
        });

        for (const cheatKey in this.cheats) {
            if (cheatKey === 'Paylines') {
                const folder = this.cheatsFolder.addFolder({
                    title: cheatKey,
                    expanded: false,
                });

                for (const cheatKey1 in this.cheats[cheatKey]) {
                    folder.addButton({ title: cheatKey1 }).on('click', async () => {
                        await this.RigNamedCheatPayline(cheatKey1);

                        MagicUI.action();
                    });
                }
                continue;
            }

            this.cheatsFolder.addButton({ title: cheatKey }).on('click', async () => {
                await this.RigNamedCheat(cheatKey);

                MagicUI.action();
            });

            const button = document.createElement('button');

            button.style.minWidth = '30%';
            button.innerText = cheatKey;
            // eslint-disable-next-line no-loop-func
            button.addEventListener('pointerdown', async () => {
                await this.RigNamedCheat(cheatKey);

                MagicUI.action();
            });
        }

        const data = {
            Pane: 'e.g. 48, 32, 51, 69, 420',
        };

        const customPane = this.cheatsFolder.addFolder({
            title: 'Custom Pane',
            expanded: false,
        });

        customPane.addBinding(data, 'Pane');
        customPane.addButton({ title: 'Send custom' }).on('click', async () => {
            const numbersText = data.Pane;
            const numbersSplit = numbersText.split(',');
            const numbersArray = numbersSplit.map((numberText) => parseInt(numberText, 10));

            await this.RigNumbers(numbersArray);

            MagicUI.action();
        });

        this.addThemeEditor();
        this.addAutoplay();
    }

    private addThemeEditor() {
        const lsSection = `${gameId}.themeEditorOpen`;

        this.themeEditorFolder = this.devTools.addFolder({
            title: 'Theme Editor',
            expanded: ls.get(lsSection) ?? false,
        });

        this.themeEditorFolder.element.addEventListener('pointerdown', () => {
            setTimeout(() => ls.set(lsSection, this.themeEditorFolder.expanded), 100);
        });

        this.themeEditorButton = this.themeEditorFolder
            .addButton({ title: 'Open theme' })
            .on('click', () => {
                if (!ls.get(lsThemeEditorSection)) {
                    this.initThemeEditor();
                }
            });

        if (ls.get(lsThemeEditorSection)) {
            this.initThemeEditor();
        }
    }

    private async initThemeEditor() {
        this.themeEditor = new ThemeEditor(this.themeEditorFolder);
        const result = await this.themeEditor.init();

        if (result) {
            ls.set(lsThemeEditorSection, result);

            this.themeEditorButton.title = 'Close theme';

            this.themeEditorButton.off('click', this.initThemeEditor);

            this.themeEditorButton.on('click', async () => {
                ls.remove(lsThemeEditorSection);
                await this.themeEditor.dispose();
                this.themeEditorFolder.dispose();
                await this.addThemeEditor();
            });

            this.themeEditorFolder
                .addButton({ title: 'Save theme' })
                .on('click', () => this.themeEditor.save());
        } else {
            ls.remove(lsThemeEditorSection);
        }
    }

    private async RigNumbers(numbers: number[]): Promise<Response> {
        const params = new URLSearchParams(window.location.search);
        const rigObject = { playerId: Connector.getUserRef(), numbers };
        const serverURL = params.get('server');

        if (!serverURL) {
            throw new Error('No server URL found');
        }

        const endpoint = new URL(serverURL).origin;

        return fetch(`${endpoint}/rigNumbers`, {
            method: 'post',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(rigObject),
        });
    }

    private async RigNamedCheat(cheatName: string): Promise<Response> {
        return this.RigNumbers(this.cheats[cheatName]);
    }

    private async RigNamedCheatPayline(cheatName: string): Promise<Response> {
        return this.RigNumbers(this.cheats.Paylines[cheatName]);
    }

    private addAutoplay() {
        ls.set(lsAutoPlaySection, false);

        const button = this.cheatsFolder.addButton({
            title: 'Start autoplay',
        });

        button.on('click', async () => {
            ls.set(lsAutoPlaySection, !ls.get(lsAutoPlaySection));

            if (ls.get(lsAutoPlaySection)) {
                button.title = 'Stop autoplay';
                MagicUI.action();
            } else {
                button.title = 'Start autoplay';
            }
        });

        let lastTimeFired = Date.now();

        EventManager.on('game:done', () => {
            if (!ls.get(lsAutoPlaySection)) return;

            if (lastTimeFired - Date.now() < 2000) {
                setTimeout(() => MagicUI.action(), 2000);
                lastTimeFired = Date.now();
            } else {
                setTimeout(() => MagicUI.action(), 100);
                lastTimeFired = Date.now();
            }
        });
    }
}
