import ls from 'localstorage-slim';
import { ControllerFactory } from 'game_libs/controllers/controllerFactory';
import DataStore from 'game_libs/dataStore';
import FreeSpinDataTypes from 'game_libs/freeSpinDataTypes';
import { GameBus } from 'game_libs/gameBus';
import { LayerFactory, LayerType } from 'game_libs/layers/layerTypes/layerFactory';
import { type SpineLayer } from 'game_libs/layers/layerTypes/spineLayer';
import { TextLayer } from 'game_libs/layers/layerTypes/textLayer';
import { Counter } from 'game_libs/utils/counter';
import { easing } from 'game_libs/utils/easing';
import DataTypes from 'game_libs/dataTypes';
import { Locale } from 'game_libs/utils/locale';
import { timeout } from 'game_libs/utils/timeout';
import { Audio } from 'game_libs/utils/audio';
import {
    POPUP_STYLE,
    POPUP_START_STYLE,
    POPUP_END_STYLE,
    POPUP_WIN_STYLE,
    POPUP_STYLE_LARGE,
    POPUP_CONTINUE_STYLE,
} from '../../config/textStyles/popUpStyle';
import { CUSTOM_DATA_TYPES } from '../parsing/customDataTypes';
import { lsAutoPlaySection } from 'game_libs/utils/cheats';
import { type PopupButtonOffset, theme } from 'game_libs/controllers/themeController';
import { Layer } from 'game_libs/layers/layerTypes/layer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const EventManager: any;

export const POPUP_LAYER = () => {
    let startFSButton: Layer;
    let retriggerFSButton: Layer;
    let stopFSButton: Layer;

    const container = LayerFactory.Create(LayerType.CONTAINER, {
        id: 'popupLayer',
        layout: {
            width: '100%',
            height: '100%',
        },
        layerChildren: [
            LayerFactory.CreateContainer({
                id: 'fsEnterPopup',
                actions: [
                    {
                        name: 'show',
                        action: async (layer: SpineLayer) => {
                            layer.visible = false;
                        },
                    },
                ],
                events: [
                    {
                        event: 'showFreeSpinEnterDialog',
                        handler: async (layer: SpineLayer) => {
/////////////////////////////////////////
                            // cheat for auto spin
                            if (ls.get(lsAutoPlaySection) === true) {
                                EventManager.emit('magic-ui:action', {
                                    bet: DataStore.get(DataTypes.TOTAL_BET),
                                    isFreeSpin: true,
                                });

                                return;
                            }
//////////////////////////////////////

                            EventManager.emit('disable-ui', {});
                            await timeout(330);
                            layer.alpha = 1;
                            EventManager.emit('disable-ui', {});
                            GameBus.emit('showOverlay');
                            layer.doParallel('showBG');
                            layer.doParallel('showText');
                            await timeout(330);
                            layer.visible = true;
                            EventManager.emit('disable-ui', {});
                            layer.interactiveChildren = true;
                        },
                    },
                    {
                        event: 'freeSpinStartClick',
                        handler: async (layer: SpineLayer) => {
                            await layer.doParallel('hide');
                            layer.interactiveChildren = false;
                            GameBus.emit('hideOverlay');
                            await timeout(330);
                            layer.visible = false;
                        },
                    },
                ],
                controllers: [
                    ControllerFactory.CreateTween({
                        tweens: [
                            {
                                startAction: 'hide',
                                from: { alpha: 1 },
                                to: { alpha: 0 },
                                time: 180,
                            },
                        ],
                    }),
                ],
                layerChildren: [
                    LayerFactory.Create(LayerType.CONTAINER, {
                        layerChildren: [
                            LayerFactory.CreateSprite({
                                texture: 'popup',
                                start: (layer) => {
                                    layer.visible = false;
                                },
                                actions: [
                                    {
                                        name: 'showBG',
                                        action: async (layer) => {
                                            layer.visible = true;
                                            layer.alpha = 0;
                                        },
                                    },
                                ],
                                layout: {
                                    scale: {
                                        x: 0.5,
                                        y: 0.5,
                                    },
                                    position: {
                                        y: -100,
                                    },
                                },
                                anchor: { x: 0.5, y: 0.5 },
                                controllers: [
                                    ControllerFactory.CreateTween({
                                        tweens: [
                                            {
                                                startAction: 'showBG',
                                                from: { alpha: 0 },
                                                to: { alpha: 1 },
                                                time: 1000,
                                                easing: easing.outCubic,
                                            },
                                        ],
                                    }),
                                ],
                                layerChildren: [
                                    LayerFactory.Create(LayerType.CONTAINER, {
                                        controllers: [
                                            ControllerFactory.CreateTween({
                                                tweens: [
                                                    {
                                                        startAction: 'showText',
                                                        from: { alpha: 0 },
                                                        to: { alpha: 1 },
                                                        time: 180,
                                                    },
                                                ],
                                            }),
                                        ],
                                        layerChildren: [
                                            LayerFactory.CreateText({
                                                text: 'youHaveWon',
                                                layout: {
                                                    anchor: {
                                                        x: 0.5,
                                                        y: 0.5,
                                                    },
                                                    position: {
                                                        y: -260,
                                                    },
                                                },
                                                style: POPUP_STYLE,
                                                actions: [
                                                    {
                                                        name: 'showText',
                                                        action: async (layer: TextLayer) => {
                                                            if (
                                                                DataStore.get<boolean>(
                                                                    DataTypes.RESUME_GAME,
                                                                )
                                                            ) {
                                                                layer.setText('youHave');
                                                            }
                                                        },
                                                    },
                                                ],
                                            }),
                                            LayerFactory.CreateText({
                                                text: '10',
                                                layout: {
                                                    anchor: {
                                                        x: 0.5,
                                                        y: 0.5,
                                                    },
                                                    position: {
                                                        y: 0,
                                                    },
                                                },
                                                style: POPUP_STYLE_LARGE,
                                                actions: [
                                                    {
                                                        name: 'showText',
                                                        action: async (layer: TextLayer) => {
                                                            layer.setText(
                                                                DataStore.get<number>(
                                                                    FreeSpinDataTypes.REMAINING,
                                                                ).toString(),
                                                            );
                                                        },
                                                    },
                                                ],
                                            }),
                                            LayerFactory.CreateText({
                                                text: 'freeSpins',
                                                layout: {
                                                    anchor: {
                                                        x: 0.5,
                                                        y: 0.5,
                                                    },
                                                    position: {
                                                        y: 260,
                                                    },
                                                },
                                                style: POPUP_STYLE,
                                            }),

                                            LayerFactory.CreateInteractionLayer({
                                                id: 'startFSButton',
                                                onClick: () => {
                                                    GameBus.emit('freeSpinStartClick');
                                                },
                                                layerChildren: [
                                                    (startFSButton = LayerFactory.CreateSprite({
                                                        id: 'button',
                                                        texture: 'button',
                                                        anchor: {
                                                            x: 0.5,
                                                            y: 0.5,
                                                        },
                                                        layerChildren: [
                                                            LayerFactory.CreateText({
                                                                layout: {
                                                                    anchor: {
                                                                        x: 0.5,
                                                                        y: 0.5,
                                                                    },
                                                                },
                                                                style: POPUP_START_STYLE,
                                                                text: Locale.get('start'),
                                                            }),
                                                        ],
                                                    })),
                                                ],
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                        ],
                    }),
                ],
            }),

            LayerFactory.CreateContainer({
                id: 'fsRetriggerPopup',
                actions: [
                    {
                        name: 'show',
                        action: async (layer: SpineLayer) => {
                            layer.visible = false;
                        },
                    },
                ],
                events: [
                    {
                        customEvent: 'showFreeSpinRetriggerDialog',
                        handler: async (layer: SpineLayer) => {
/////////////////////////////////////////
                            if (ls.get(lsAutoPlaySection) === true) {
                                GameBus.emitCustom('freeSpinRetriggerClick');

                                return;
                            }
//////////////////////////////////////
                            EventManager.emit('disable-ui', {});
                            await timeout(330);
                            EventManager.emit('disable-ui', {});
                            layer.alpha = 1;
                            GameBus.emit('showOverlay');
                            layer.doParallel('showBG');
                            layer.doParallel('showText');
                            await timeout(330);
                            layer.visible = true;
                            EventManager.emit('disable-ui', {});
                            layer.interactiveChildren = true;
                        },
                    },
                    {
                        customEvent: 'freeSpinRetriggerClick',
                        handler: async (layer: SpineLayer) => {
                            await layer.doParallel('hide');
                            layer.interactiveChildren = false;
                            GameBus.emit('hideOverlay');
                            await timeout(330);
                            layer.visible = false;
                        },
                    },
                ],
                controllers: [
                    ControllerFactory.CreateTween({
                        tweens: [
                            {
                                startAction: 'hide',
                                from: { alpha: 1 },
                                to: { alpha: 0 },
                                time: 180,
                            },
                        ],
                    }),
                ],
                layerChildren: [
                    LayerFactory.Create(LayerType.CONTAINER, {
                        layerChildren: [
                            LayerFactory.CreateSprite({
                                texture: 'popup',
                                start: (layer) => {
                                    layer.visible = false;
                                },
                                actions: [
                                    {
                                        name: 'showBG',
                                        action: async (layer) => {
                                            layer.visible = true;
                                        },
                                    },
                                ],
                                layout: {
                                    scale: {
                                        x: 0.5,
                                        y: 0.5,
                                    },
                                    position: {
                                        y: -100,
                                    },
                                },
                                anchor: { x: 0.5, y: 0.5 },
                                controllers: [
                                    ControllerFactory.CreateTween({
                                        tweens: [
                                            {
                                                startAction: 'showBG',
                                                from: { alpha: 0 },
                                                to: { alpha: 1 },
                                                time: 1000,
                                                easing: easing.outCubic,
                                            },
                                        ],
                                    }),
                                ],
                                layerChildren: [
                                    LayerFactory.Create(LayerType.CONTAINER, {
                                        controllers: [
                                            ControllerFactory.CreateTween({
                                                tweens: [
                                                    {
                                                        startAction: 'showText',
                                                        from: { alpha: 0 },
                                                        to: { alpha: 1 },
                                                        time: 180,
                                                    },
                                                ],
                                            }),
                                        ],
                                        layerChildren: [
                                            LayerFactory.CreateText({
                                                text: 'youHaveWon',
                                                layout: {
                                                    anchor: {
                                                        x: 0.5,
                                                        y: 0.5,
                                                    },
                                                    position: {
                                                        y: -260,
                                                    },
                                                },
                                                style: POPUP_STYLE,
                                                actions: [
                                                    {
                                                        name: 'showText',
                                                        action: async (layer: TextLayer) => {
                                                            if (
                                                                DataStore.get<boolean>(
                                                                    DataTypes.RESUME_GAME,
                                                                )
                                                            ) {
                                                                layer.setText('youHave');
                                                            }
                                                        },
                                                    },
                                                ],
                                            }),
                                            LayerFactory.CreateText({
                                                text: '10',
                                                layout: {
                                                    anchor: {
                                                        x: 0.5,
                                                        y: 0.5,
                                                    },
                                                    position: {
                                                        y: 0,
                                                    },
                                                },
                                                style: POPUP_STYLE_LARGE,
                                                actions: [
                                                    {
                                                        name: 'showText',
                                                        action: async (layer: TextLayer) => {
                                                            layer.setText(
                                                                DataStore.get<number>(
                                                                    CUSTOM_DATA_TYPES.RETRIGGERED_FREESPIN_COUNT,
                                                                ).toString(),
                                                            );
                                                        },
                                                    },
                                                ],
                                            }),
                                            LayerFactory.CreateText({
                                                text: 'MORE FREE SPINS',
                                                layout: {
                                                    anchor: {
                                                        x: 0.5,
                                                        y: 0.5,
                                                    },
                                                    position: {
                                                        y: 260,
                                                    },
                                                },
                                                style: POPUP_STYLE,
                                            }),
                                        ],
                                    }),

                                    LayerFactory.CreateInteractionLayer({
                                        id: 'retriggerFSButton',
                                        onClick: () => {
                                            GameBus.emitCustom('freeSpinRetriggerClick');
                                        },
                                        layout: {
                                            position: {
                                                x: 0,
                                                y: 300,
                                            },
                                        },
                                        layerChildren: [
                                            (retriggerFSButton = LayerFactory.CreateSprite({
                                                id: 'button',
                                                texture: 'button',
                                                anchor: {
                                                    x: 0.5,
                                                    y: 0.5,
                                                },
                                                layerChildren: [
                                                    LayerFactory.CreateText({
                                                        layout: {
                                                            anchor: {
                                                                x: 0.5,
                                                                y: 0.5,
                                                            },
                                                        },
                                                        style: POPUP_START_STYLE,
                                                        text: Locale.get('start'),
                                                    }),
                                                ],
                                            })),
                                        ],
                                    }),
                                ],
                            }),
                        ],
                    }),
                ],
            }),

            LayerFactory.CreateContainer({
                id: 'fsExitPopup',
                events: [
                    {
                        event: 'showFreeSpinExitDialog',
                        handler: async (layer: SpineLayer) => {
/////////////////////////////////////////
                            if (ls.get(lsAutoPlaySection) === true) {
                                return;
                            }
//////////////////////////////////////
                            EventManager.emit('disable-ui', {});
                            GameBus.emit('showOverlay');
                            layer.visible = true;
                            layer.do('showText');
                            layer.do('showBG');
                            layer.interactiveChildren = true;
                            await timeout(64);
                            EventManager.emit('disable-ui', {});
                        },
                    },

                    {
                        event: 'freeSpinExitClick',
                        handler: async (layer: SpineLayer) => {
                            await Promise.all([layer.do('hideBG'), layer.do('hideText')]);

                            // await layer.play("end");
                            GameBus.emit('hideOverlay');
                            await timeout(330);
                            layer.visible = false;
                            EventManager.emit('enable-ui', {});
                            // layer.interactiveChildren = false;
                        },
                    },
                ],
                controllers: [
                    ControllerFactory.CreateTween({
                        tweens: [
                            {
                                startAction: 'hide',
                                from: { alpha: 1 },
                                to: { alpha: 0 },
                                time: 180,
                            },
                        ],
                    }),
                ],
                actions: [
                    {
                        name: 'show',
                        action: async (layer: SpineLayer) => {
                            layer.visible = false;
                        },
                    },
                ],

                layerChildren: [
                    LayerFactory.CreateSprite({
                        texture: 'popup',
                        layout: {
                            position: {
                                x: 0,
                                y: -50,
                            },
                            scale: {
                                x: 0.5,
                                y: 0.5,
                            },
                        },
                        anchor: {
                            x: 0.5,
                            y: 0.5,
                        },
                        controllers: [
                            ControllerFactory.CreateTween({
                                tweens: [
                                    {
                                        startAction: 'showBG',
                                        from: { alpha: 0 },
                                        to: { alpha: 1 },
                                        time: 180,
                                    },
                                    {
                                        startAction: 'hideBG',
                                        from: { alpha: 1 },
                                        to: { alpha: 0 },
                                        time: 180,
                                    },
                                ],
                            }),
                        ],
                        layerChildren: [
                            LayerFactory.CreateContainer({
                                controllers: [
                                    ControllerFactory.CreateTween({
                                        tweens: [
                                            {
                                                startAction: 'showText',
                                                from: { alpha: 0 },
                                                to: { alpha: 1 },
                                                time: 180,
                                            },
                                            {
                                                startAction: 'hideText',
                                                from: { alpha: 1 },
                                                to: { alpha: 0 },
                                                time: 180,
                                            },
                                        ],
                                    }),
                                ],
                                layerChildren: [
                                    LayerFactory.CreateText({
                                        text: 'freeSpinsWin',
                                        layout: {
                                            anchor: {
                                                x: 0.5,
                                                y: 0.5,
                                            },
                                            position: {
                                                y: -210,
                                            },
                                        },
                                        style: POPUP_END_STYLE,
                                    }),
                                    LayerFactory.CreateText({
                                        text: '0',
                                        layout: {
                                            anchor: {
                                                x: 0.5,
                                                y: 0.5,
                                            },
                                            position: {
                                                y: 40,
                                            },
                                        },
                                        style: POPUP_WIN_STYLE,
                                        events: [
                                            {
                                                event: 'showFreeSpinExitDialog',
                                                handler: async (layer: TextLayer) => {
                                                    Audio.play('WinTotalDigitCounter', true);
                                                    await Counter(
                                                        0,
                                                        DataStore.get(FreeSpinDataTypes.TOTAL_WIN),
                                                        easing.linear,
                                                        2000,
                                                        (t: number) => {
                                                            layer.setText((t / 100).toFixed(2));
                                                        },
                                                        'bigWinTick',
                                                    );
                                                    Audio.stop('WinTotalDigitCounter');
                                                },
                                            },
                                        ],
                                    }),

                                    LayerFactory.CreateInteractionLayer({
                                        id: 'stopFSButton',
                                        onClick: () => {
                                            GameBus.emit('freeSpinExitClick');
                                        },
                                        layout: {
                                            position: {
                                                x: 0,
                                                y: 300,
                                            },
                                        },
                                        layerChildren: [
                                            (stopFSButton = LayerFactory.CreateSprite({
                                                id: 'button',
                                                texture: 'button',
                                                anchor: {
                                                    x: 0.5,
                                                    y: 0.5,
                                                },
                                                layerChildren: [
                                                    LayerFactory.CreateText({
                                                        layout: {
                                                            anchor: {
                                                                x: 0.5,
                                                                y: 0.5,
                                                            },
                                                            position: {
                                                                x: 0,
                                                                y: 10,
                                                            },
                                                        },
                                                        style: POPUP_CONTINUE_STYLE,
                                                        text: Locale.get('continue'),
                                                    }),
                                                ],
                                            })),
                                        ],
                                    }),
                                ],
                            }),
                        ],
                    }),
                ],
            }),
        ],
    });

/////////////////
    theme.onchange(({ popupButtonOffset }) => updatePositions(popupButtonOffset));
//////////////

    updatePositions(theme.popupButtonOffset);

    function updatePositions(popupButtonOffset: PopupButtonOffset) {
        startFSButton?.setLayoutConfigs([
            {
                position: {
                    y: popupButtonOffset.startFS ?? 0,
                },
            },
        ]);
        startFSButton.visualUpdate();

        retriggerFSButton?.setLayoutConfigs([
            {
                position: {
                    y: popupButtonOffset.retriggerFS ?? 0,
                },
            },
        ]);
        retriggerFSButton.visualUpdate();

        stopFSButton?.setLayoutConfigs([
            {
                position: {
                    y: popupButtonOffset.stopFSButton ?? 0,
                },
            },
        ]);
        stopFSButton.visualUpdate();
    }

    return container;
};
