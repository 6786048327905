import { getUrlParam } from 'game_libs/utils/gtUrlParams';
import { get, set, del, clear } from 'idb-keyval';

const gameId = getUrlParam('gameId');

export class FileSystem {
    private dirHandle!: FileSystemDirectoryHandle;
    #isInitiated = false;
    folderFiles: FolderData;

    constructor() {
        if (!FileSystem.isSupported) {
            console.error('This browser does not support the File System Access API.');
        }
    }

    async init(): Promise<FolderData> {
        try {
            this.dirHandle = await (window as any).showDirectoryPicker({ mode: 'readwrite' });
        } catch (error) {
            console.error(error);

            return [];
        }

        if (!this.dirHandle) {
            console.error('User cancelled, or otherwise failed to open a folder.');

            return [];
        }

        this.folderFiles = await this.getDirFiles(this.dirHandle, this.dirHandle.name);

        this.#isInitiated = true;

        return this.folderFiles;
    }

    async selectFile(fileTypes?: string[]): Promise<FileData | null> {
        try {
            const file = await (window as any).showOpenFilePicker({
                types: [
                    {
                        description: 'Images',
                        accept: {
                            '*/*': [fileTypes],
                        },
                    },
                ],
                excludeAcceptAllOption: true,
                multiple: false,
            });

            return file;
        } catch (error) {
            console.error(error);
        }

        return null;
    }

    async dispose() {
        this.#isInitiated = false;
        await clear();
    }

    private async getDirFiles(
        dirHandle: FileSystemDirectoryHandle,
        path: string,
    ): Promise<FolderData> {
        const dirs = [];
        const files = [];

        for await (const entry of (dirHandle as any).values()) {
            const nestedPath = `${path}/${entry.name}`;

            if (entry.kind === 'file') {
                files.push(
                    entry.getFile().then((file: any) => {
                        file.directoryHandle = dirHandle;
                        file.handle = entry;

                        return Object.defineProperty(file, 'webkitRelativePath', {
                            configurable: true,
                            enumerable: true,
                            get: () => nestedPath,
                        });
                    }),
                );
            } else if (entry.kind === 'directory') {
                dirs.push(this.getDirFiles(entry, nestedPath));
            }
        }

        return [...(await Promise.all(dirs)).flat(), ...(await Promise.all(files))] as FolderData;
    }

    static get isSupported() {
        return (
            'showDirectoryPicker' in window &&
            (() => {
                try {
                    return window.self === window.top;
                } catch {
                    return false;
                }
            })()
        );
    }

    get isInitiated() {
        return this.#isInitiated;
    }

    async writeFile(fileName: string, data: any): Promise<void> {
        if (!this.#isInitiated) {
            await this.init();
        }

        const fileHandle = await this.dirHandle.getFileHandle(fileName, { create: true });

        const writable = await fileHandle.createWritable();

        await writable.write(JSON.stringify(data, null, 4));
        await writable.close();
    }

    async getFileContent(fileName: string) {
        const fileNameInDB = `${gameId}.${fileName}_handle`;
        let fileHandle = await get(fileNameInDB);

        if (!fileHandle) {
            if (!this.#isInitiated) {
                await this.init();
            }

            if (!this.folderFiles.length) {
                console.error('No files found in the folder.');

                return {};
            }

            const file = this.folderFiles.find((file: FileData) => file.name === fileName);

            if (!file) {
                console.error(`File ${fileName} not found.`);
            }

            fileHandle = file.handle;

            await set(fileNameInDB, fileHandle);
        }

        if (!fileHandle) {
            console.error(`File ${fileName} not found.`);

            return {};
        }

        try {
            const fileDataImport = await fileHandle.getFile();
            const fileDataImportText = await fileDataImport.text();
            const fileData = JSON.parse(fileDataImportText);

            return fileData;
        } catch (error) {
            await del(fileNameInDB);

            console.error(`Error parsing file ${fileName}`, error);
        }

        return {};
    }
}

export type FileData = {
    directoryHandle: FileSystemDirectoryHandle;
    handle: FileSystemFileHandle;
    webkitRelativePath: string;
    lastModified: string;
    lastModifiedDate: string;
    name: string;
    size: number;
    type: string;
};
export type FolderData = FileData[];
