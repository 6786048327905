import SlotMachineFeature from './features/slotMachineFeature';
import ReelEntity from './reelEntity';
import { Container, DisplayObject } from '@pixi/display';
import { GameBus } from '../gameBus';
import { ICoord } from '../platformInterface';
import { theme } from 'game_libs/controllers/themeController';

export default class SlotMachineEntity extends Container {
    private reels: ReelEntity[] = [];
    private stateIndex = 0;
    private states: string[] = [];
    private features: Map<string, Array<SlotMachineFeature>> = new Map();
    private symbolIDs: string[];

/////////////////
    constructor() {
        super();

        theme.onchange((theme) => {
            const { symbolSize, reelSpacing, symbolScaling } = theme;

            this.setReelsSpacing(symbolSize, reelSpacing);
            this.reels.forEach((reel) => reel.setSymbolsScale(symbolScaling));
        });
    }
//////////////

    public get reelCount(): number {
        return this.reels.length;
    }

    public addState(state: string) {
        this.states.push(state);
        this.features.set(state, []);
    }

    public addFeature(state: string, feature: SlotMachineFeature) {
        this.features.get(state)?.push(feature);
    }

    public setSymbol(symbolID: string, reel: number, row: number) {
        this.reels[reel].setSymbol(symbolID, row);
    }

    public setSymbolIDs(symbolIDs: Array<string>) {
        this.symbolIDs = symbolIDs;
    }

    public getSymbolSpots(symbolID: string): ICoord[] {
        const coords: ICoord[] = [];

        this.reels.forEach((reel, index) => {
            if (reel.countSymbols(symbolID) > 0) {
                const rows = reel.getSymbolRows(symbolID);

                rows.forEach((row) => {
                    coords.push({ reel: index, row });
                });
            }
        });

        return coords;
    }

    public async expandReels(): Promise<ReelEntity[]> {
        const expandingReels: ReelEntity[] = [];

        this.reels?.forEach((reel) => {
            if (reel.isExpandable) {
                expandingReels.push(reel);
            }
        });

        for (const reel of this.reels) {
            await reel.expand();
        }

        return expandingReels;
    }

    public addReel(reel: ReelEntity) {
        this.reels.push(reel);
        this.addChild(reel);
    }

    public getSymbol(reel: number, row: number): DisplayObject {
        return this.reels[reel].getSymbol(row);
    }

    public getReel(reelIndex: number) {
        return this.reels[reelIndex];
    }

    public setPanel(panel: string[][]) {
        for (let i = 0; i < panel.length; i++) {
            const symbols = panel[i];

            this.reels[i].reset();

            for (let j = 0; j < symbols.length; j++) {
                const symbol = symbols[j];

                this.reels[i].addSymbol(symbol, j);
            }
        }
    }

    public async playStateFeatures(stateName: string) {
        const features = this.features.get(stateName);

        console.debug(`BEGINNING STATE: ${stateName} (${features?.length || 0} features)`);
        features?.forEach((feature) => {
            console.debug(`-> ${feature.constructor.name}`);
        });
        if (features) {
            for (let j = 0; j < features.length; j++) {
                const override = features[j].checkOverrideCondition();

                if (override && features[j].condition()) {
                    await features[j].trigger(this);
                } else if (override) {
                    await features[j].trigger(this);
                }
            }
        }
        console.debug(`ENDING STATE: ${stateName}`);
    }

    public async initialise() {
        await this.playStateFeatures(this.states[this.states.length - 1]);
        GameBus.emit('slotMachineFeaturesEnd');
    }

    public async start() {
        this.stateIndex = 0;
        for (let i = 0; i < this.states.length; i++) {
            await this.playStateFeatures(this.states[i]);
            this.stateIndex++;
        }
        GameBus.emit('slotMachineFeaturesEnd');
    }

    deactivateAllSymbols() {
        this.reels.forEach((reel) => {
            reel.deactivateAllSymbols();
        });
    }

    get hasExpandingReels() {
        return this.reels.some((reel) => reel.isExpandable);
    }

    setReelsSpacing(
        symbolSize: {
            w: number;
            h: number;
        },
        spacing: number,
    ) {
        this.reels.forEach((reel, index) => {
            reel.x = symbolSize.w * index + spacing * index;
        });
    }
}
