import { LayerFactory, LayerType } from 'game_libs/layers/layerTypes/layerFactory';
import { CustomPaylinesController } from 'controllers/customPaylinesController';
import { type SpineLayer } from 'game_libs/layers/layerTypes/spineLayer';
import { Layer } from 'game_libs/layers/layerTypes/layer';
import { lineMap } from '../../config/paylines';
import { theme } from 'game_libs/controllers/themeController';

let payLineLayer: Layer;

export const PAY_LINES_LAYER = () => {
    const payLinesLayer = LayerFactory.Create(LayerType.CONTAINER, {
        id: 'paylinesLayer',
        layout: theme.payLines,
        start: (layer: Layer) => {
            payLineLayer = layer;

            layer.visible = false;
        },
        controllers: [
            new CustomPaylinesController({
                paylineFactory: () => LayerFactory.CreateSpine({ spineData: 'paylines' }),
                playPaylineAnimation: async (spineLayer: SpineLayer, paylineIndex: number) => {
                    const config = lineMap[paylineIndex];

                    if (config.vInverted) {
                        spineLayer.scale.y = -1;
                    } else {
                        spineLayer.scale.y = 1;
                    }

                    spineLayer.y = config.yOffset;

                    await spineLayer.play(config.animationName);

                    payLineLayer.visible = true;
                },
            }),
        ],
    });

/////////////////
    theme.onchange(({ payLines }) => {
        payLinesLayer.setLayoutConfigs([payLines]);
        payLinesLayer.visualUpdate();
    });
//////////////

    return payLinesLayer;
};
