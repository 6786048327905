import DataStore from '../dataStore';
import DataTypes from '../dataTypes';
import SlotMachineFeature from './features/slotMachineFeature';
import SpinFeature from './features/spinFeature';
import StartFeature from './features/startFeature';

import TotalWinFeature from './features/wins/totalWinFeature';
import ReelEntity from './reelEntity';
import SlotMachineEntity from './slotMachineEntity';
import StopFeature from './features/stopFeature';

/////////////
import { GameBus } from 'game_libs/gameBus';
import { cheats } from '../../../main';
import { timeout } from 'game_libs/utils/timeout';
//////////

export interface ISlotMachineConfiguration {
    symbolFactory: (symbolID: string, finalPanel: boolean) => any;
    symbolIDs: string[];
    anticipationRule?: (reel: ReelEntity, index: number, slotMachine: SlotMachineEntity) => boolean;
    expandedSymbolCondition?: () => boolean;
    features?: Map<string, SlotMachineFeature>;
    symbolWidth: number;
    symbolHeight: number;
    createMask?: boolean;
    spinSpeed?: number;
    chunkSize?: number;
    hiddenReels?: number[];
    reelSpacing?: number;
}

export enum SLOT_MACHINE_STATES {
    STARTING = 'STARTING',
    SPINNING = 'SPINNING',
    STOPPING = 'STOPPING',
    IDLE = 'IDLE',
}

export class SlotMachineFactory {
    static createSlotMachine(config: ISlotMachineConfiguration): SlotMachineEntity {
        const sm = new SlotMachineEntity();

/////////////////////
        cheats.add('anticipation', async () => {
            for (let i = 0; i < sm.reelCount; i++) {
                i === 0
                    ? GameBus.emit('anticipationStart', i, sm)
                    : GameBus.emit('anticipationContinue', i, sm);

                await timeout(2000);
            }

            GameBus.emit('anticipationStop');
        });
//////////////////

        if (!config.reelSpacing) {
            config.reelSpacing = 0;
        }

        for (const state in SLOT_MACHINE_STATES) {
            sm.addState(state);
        }

        const taxonomy = DataStore.get<any>(DataTypes.TAXONOMY);
        let reelIndex = 0;

        for (let i = 0; i < taxonomy.length; i++) {
            const reel = new ReelEntity({
                direction: 1,
                spinSpeed: config.spinSpeed || 4,
                chunkSize: config.chunkSize || 3,
                rows: taxonomy[i],
            });

            reel.setSymbolFactory((symbolID, finalPanel) =>
                config.symbolFactory(symbolID, finalPanel),
            );
            reel.setSymbolDimensions(config.symbolWidth, config.symbolHeight);
            reel.setSymbolIDs(config.symbolIDs);

            (reel as any).name = `reel_${i}`;
            reel.x = config.symbolWidth * reelIndex + config.reelSpacing * i;
            if (!config.hiddenReels?.includes(i)) {
                reelIndex++;
            } else {
                reel.visible = false;
            }
            sm.addReel(reel);
        }

        const startingFeature = config.features?.get(SLOT_MACHINE_STATES.STARTING);

        if (startingFeature) {
            sm.addFeature(SLOT_MACHINE_STATES.STARTING, startingFeature);
        } else {
            sm.addFeature(
                SLOT_MACHINE_STATES.STARTING,
                new StartFeature({
                    reelsStartDelay: [0, 0, 0, 0, 0],
                    startTime: 100,
                }),
            );
        }

        const spinningFeature = config.features?.get(SLOT_MACHINE_STATES.SPINNING);

        if (spinningFeature) {
            sm.addFeature(SLOT_MACHINE_STATES.SPINNING, spinningFeature);
        } else {
            sm.addFeature(
                SLOT_MACHINE_STATES.SPINNING,
                new SpinFeature({
                    time: 1000,
                }),
            );
        }

        const stoppingFeature = config.features?.get(SLOT_MACHINE_STATES.STOPPING);

        if (stoppingFeature) {
            sm.addFeature(SLOT_MACHINE_STATES.STOPPING, stoppingFeature);
        } else {
            sm.addFeature(
                SLOT_MACHINE_STATES.STOPPING,
                new StopFeature({
                    stopTime: 400,
                    reelsStopDelay: [200, 500, 800, 1100, 1400, 1700],
                    anticipationRule: config.anticipationRule as any,
                }),
            );
        }

        const idleFeature = config.features?.get(SLOT_MACHINE_STATES.IDLE);

        if (idleFeature) {
            sm.addFeature(SLOT_MACHINE_STATES.IDLE, idleFeature);
        } else {
            sm.addFeature(SLOT_MACHINE_STATES.IDLE, new TotalWinFeature({ showPaylines: false }));
        }

        return sm;
    }
}
